import React from 'react';
import { Container, Col, Row, ProgressBar, Spinner, Form, FormText, Button } from 'react-bootstrap';
import { BarChartLineFill } from 'react-bootstrap-icons';
import MaterialTable from 'material-table'

import { api } from '../utils/requests'
import ErrorAlert from '../utils/ErrorAlert'

import '../../css/results.css'


class ResultsShow extends React.Component {
    constructor(props){
        super()

        this.data = props.data
        this.columns = props.columns
    }
    render(){
        return(
            <MaterialTable title=''
            data={this.data}
            columns={this.columns}
            options={
                {
                    filtering: true,
                    exportButton: true,
                    exportAllData: true
                }
            }
            />
        )
    }
}

class ResultsLoading extends React.Component {
    render(){
        return(
            <Container className='spinner-container'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        )
    }
}

class ResultsUpload extends React.Component {
    constructor(props) {
        super(props)

        this.toggleLoading = this.props.toggleLoading
        this.setUploaded = this.props.setUploaded
        this.setData = this.props.setData
        this.setError = this.props.setError

        this.handleSubmit = event => {
            event.preventDefault();
            this.toggleLoading()

            let formData = new FormData()
            formData.append('file', this.state.file)
            formData.append('preuploaded', this.state.preuploaded)

            this.setError(false)

            api.post('visualize/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(response => {
                    debugger;
                    this.setData(response.data.data)
                    this.setUploaded()
                })
                .catch(err => {
                this.setError(err)
                console.log(err.message)
                this.toggleLoading()
            })
        }

        this.state = {
            preuploadedList: [],
            preuploaded: '',
            file: null,
            error: this.props.error
        }
    }

    componentDidMount(){
        api
        .get('uploaded/')
        .then(res => {
          let preuploadedList = []
          try {
            for (let i = 0; i < res.data.list.length; i++) {
                preuploadedList.push(res.data.list[i])
            }
          } catch {
            console.warn('Error while parsing list')
          }
          preuploadedList = Array.from(new Set(preuploadedList))
          this.setState({
            preuploadedList: preuploadedList,
            preuploaded: ''
          })
        })
        .catch(err => {
            this.setError(err)
            console.error(err)
        })
    }


    componentDidUpdate(prevProps){
        if(prevProps.error !== this.props.error){
            this.setState({          
                error: this.props.error
            });
        }
    }


    render(){
        return(
            <Row className='Content-box'>
                <Form onSubmit={this.handleSubmit}>
                    {this.state.error ?
                    <>
                      <Row>
                          <ErrorAlert error={this.state.error}/>
                      </Row>
                    </>
                    :
                    <></>
                    }
                    <Row>
                        <Col className='col-12 col-md-6'>
                            <Form.Group>
                                <label htmlFor="file" style={{ display: 'block' }}>
                                    Upload File
                                </label>
                                <input
                                    id={'file'}
                                    type={'file'}
                                    style={{ border: '0' }}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={event => {
                                    this.setState({
                                        file: event.target.files[0]
                                    })
                                    }}
                                />
                                <FormText color="muted" align={'left'}>
                                    <b>Upload the file received at your email address.</b><br/>
                                </FormText>
                            </Form.Group>
                        </Col>
                        <Col className='col-12 col-md-6'>
                            <Form.Group>
                                <label htmlFor="studiokw" style={{ display: 'block' }}>
                                    Pre-uploaded studiokw
                                </label>
                                <select
                                    id="preuploaded"
                                    className='preuploaded form-control'
                                    value={this.state.preuploaded}
                                    style={{ height: '44px' }}
                                    onChange={event => {
                                    this.setState({
                                        preuploaded: event.target.value
                                    })
                                    }}
                                    >
                                    <option key={'blank'} value={''}/>
                                    {this.state.preuploadedList.map(preuploaded => {
                                    return (
                                        <option key={preuploaded} value={preuploaded}>
                                        {preuploaded}
                                        </option>
                                    )
                                    })}
                                </select>
                                <FormText color="muted" align={'left'}>
                                    <strong>Select an analyzed studiokw from our database without uploading a new one</strong><br />
                                </FormText>    
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='next-label'>
                        <b>Either upload a file or select a model</b><br />
                    </Row>
                    <Row className='next'>
                        <Button type="submit">Next</Button>
                    </Row>
                </Form>
            </Row>
        )
    }
}

export default class Results extends React.Component {
    constructor(){
        super()

        this.state = {
            isLoading: false,
            uploadedData: false,
            data: [],
            error: false
        }

        this.columns = [
            {
                title:'Keyword',
                field:'Keyword'
            },
            {
                title:'Informational',
                field:'Informational',
                render: rowData => <ProgressBar now={rowData.Informational} label={`${rowData.Informational}%`}/>
            },
            {
                title:'Transactional',
                field:'Transactional',
                render: rowData => <ProgressBar now={rowData.Transactional} label={`${rowData.Transactional}%`}/>
            },
            {
                title:'Navigational',
                field:'Navigational',
                render: rowData => <ProgressBar now={rowData.Navigational} label={`${rowData.Navigational}%`}/>
            },
            {
                title:'Total',
                render: rowData => 
                <ProgressBar>
                    <ProgressBar variant="info" now={rowData.Informational} key={1} />
                    <ProgressBar variant="danger" now={rowData.Transactional} key={2} />
                    <ProgressBar variant="warning" now={rowData.Navigational} key={3} />
                </ProgressBar>
            },
            {
                title:'Local',
                field:'Local',
                type:'boolean'
            },
            {
                title:'Ecommerce',
                field:'Ecommerce',
                render: rowData => <ProgressBar now={rowData.Ecommerce} label={`${rowData.Ecommerce}%`}/>
            },
            {
                title:'Multibrand',
                field:'Multibrand',
                render: rowData => <ProgressBar now={rowData.Multibrand} label={`${rowData.Multibrand}%`}/>
            },
            {
                title:'Publishing',
                field:'Publishing',
                render: rowData => <ProgressBar now={rowData.Publishing} label={`${rowData.Publishing}%`}/>
            }
        ]

        this.toggleLoading = () => {
            if (this.state.isLoading){
                this.setState({
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: true
                })
            }
        }

        this.setUploaded = () => {
            this.setState({
                isLoading: false,
                uploadedData: true
            })
        }

        this.setData = data => {
            this.setState({
                data: data
            })
        }

        this.setError = error => {
            this.setState({
                error: error
            })
        }
    }


    render() {
        return (
            <>
            <h1><BarChartLineFill/>Results</h1>
            {
                this.state.isLoading
                ?
                <ResultsLoading/>
                :
                this.state.uploadedData
                ?
                <ResultsShow data={this.state.data} columns={this.columns}/>
                :
                <ResultsUpload toggleLoading={this.toggleLoading} setUploaded={this.setUploaded} setData={this.setData} error={this.state.error} setError={this.setError}/>
            }
            </>
        )
    }
  }