import React from 'react'
import { Row, Image, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { HouseDoorFill, PlusSquareFill, BarChartLineFill, UiChecks, FolderFill } from 'react-bootstrap-icons';

import ToolLogo from '../../media/images/tool_logo.jpg'

// Css
import '../../css/menu.css'

export default class Menu extends React.Component {
    constructor() {
        super()

        this.handleLinkClick = (e) => {
            const active_link = document.getElementsByClassName('active_link')[0]

            active_link && active_link.classList.remove('active_link')
            e.target.classList.add('active_link')
        }
    }

    componentDidMount() {
        const current_link = document.getElementById(window.location.pathname);
        current_link && current_link.classList.add('active_link');
    }

    render() {
        const feedback = () => {
            window.open(
                "https://docs.google.com/spreadsheets/d/16UkXxXJaY3m7Ggmy8Sqc8Yh44-a8KyuTrQ9736q0y38/edit#gid=0",
                "_blank"
            );
        };

        return (
            <Container className='MenuContainer'>
                <Row className='ToolLogo Logo'>
                    <Image src={ToolLogo} alt="Keyword intentions tool Logo" fluid />
                </Row>
                <Row className='MenuItems'>
                    <nav>
                        <ul>
                            <li>
                                <Link to='/' onClick={this.handleLinkClick}>
                                    <span id='/'><HouseDoorFill />Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/insert' onClick={this.handleLinkClick}>
                                    <span id='/insert'><PlusSquareFill />New analysis</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/old' onClick={this.handleLinkClick}>
                                    <span id='/old'><FolderFill />Old analyses</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/check' onClick={this.handleLinkClick}>
                                    <span id='/check'><UiChecks />Check Jobs</span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/results' onClick={this.handleLinkClick}>
                                    <span id='/results'><BarChartLineFill />Results</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </Row>
                <Row>
                    <button className='feedback-btn btn-primary' onClick={feedback} variant={"info"}>
                        Feedback
                    </button>
                </Row>
            </Container>
        )
    }
}