import axios from 'axios'
import auth from '../../auth/service'

let BASE_URL = ''
if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://intentions.webranking.biz'
} else {
  BASE_URL = 'http://localhost:9992'
}

const baseApiAddress = `${BASE_URL}/api/1/`

let _api = axios.create({
  baseURL: baseApiAddress
})

_api.interceptors.request.use(
  config => {
    //if (process.env.NODE_ENV === 'production') {
      if (config.baseURL === baseApiAddress && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${auth.getAccessToken()}`
      }
    //}
    return config
  },
  error => Promise.reject(error)
)

export const api = _api