import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

function ErrorAlert(props) {
    const [error] = useState(props.error)
    let errorCode = error.response ? error.response.status : 500
    let errorMessage = error.message || 'Something went wrong'
    return (
      <>
        <Alert className={'centered-alert'} variant="danger">
          <Alert.Heading>Error {errorCode}</Alert.Heading>
          <p>
            {errorMessage || 'Something went wrong'}. Try again or contact <a href="mailto: innovation.services@webranking.it">innovation.services@webranking.it</a>
          </p>
        </Alert>
      </>
    );
  }

export default ErrorAlert