/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import { Row, Col, Form, FormText, Button, Container, Spinner, Modal } from 'react-bootstrap';
import { PlusSquareFill } from 'react-bootstrap-icons';
import Worker from "worker-loader!../../webWorker.js";

import { api } from '../utils/requests'
import ErrorAlert from '../utils/ErrorAlert'

import '../../css/insert.css'


class Loading extends React.Component {
  render() {
    return (
      <Container className='spinner-container'>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    )
  }
}

class Done extends React.Component {
  constructor(props) {
    super()

    this.back = props.handleBack
  }

  render() {
    return (
      <>
        <Row className='success'>
          <span><strong>Done</strong><br />
          Nice. You will receive an email when the work is done</span>
        </Row>
        <Row className='success-button'>
          <Button type="submit" onClick={this.back}>Back</Button>
        </Row>
      </>
    )
  }
}


class Missing extends React.Component {
  constructor(props) {
    super()

    this.reset = props.resetMissing
    this.keywords = props.keywords
    this.upload = props.handleUpload
    this.back = props.handleBack
    this.missing = props.missing

    this.handleYes = () => {
      this.upload()
      this.reset()
    }

    this.handleNo = () => {
      this.reset()
      this.back()
    }
  }

  render() {
    return (
      <>
        {
          this.missing.is_all
            ?
            <Container>
              <Row className='success'>
                <span><strong>Something went wrong</strong><br />
                It appears that all your input is not present in our serp database. Please upload your keyword
                on <a href="http://ranking.webranking.tools/" target="_blank" rel="noopener noreferrer">ranking</a> befor using this tool.</span>
              </Row>
              <Row className='success-button'>
                <Button type="submit" onClick={this.handleNo}>Back</Button>
              </Row>
            </Container>
            :
            <Container>
              <Row className='success'>
                <span><strong>Attention</strong><br />
                It appears that {this.missing.count ? this.missing.count : 'some'} of the {this.keywords.length} keywords are not present in our serp database. Please always upload your keywords
                on <a href="http://ranking.webranking.tools/" target="_blank" rel="noopener noreferrer">ranking</a> befor using this tool.<br />
                If you wish, you can continue and analyze the keywords that we found</span>
              </Row>
              <Row className='success-button'>
                <Button type="submit" onClick={this.handleNo}>No</Button>
                <Button type="submit" onClick={this.handleYes}>Yes</Button>
              </Row>
            </Container>
        }
      </>
    )
  }
}


export default class Insert extends React.Component {
  constructor() {
    super()

    this.state = {
      keywords: [],
      locations: [],
      file: null,
      nome: '',
      mail: '',
      location: '',
      inserted: false,
      missing: { is_missing: false, is_all: false, count: 0 },
      ignore: false,
      loading: false,
      error: false,
      job_results: false,
      customer: '',
      brand: '',
      sector: ''
    }

    this.get_keyword_column_index = (columns) => {
      const keyword_options = ['keyword', 'Keyword', 'keywords', 'Keywords', 'Keyword list', 'Keywords list', 'keyword list',
        'keywords list']
      for (let i in keyword_options) {
        if (columns.includes(keyword_options[i])) {
          return columns.indexOf(keyword_options[i])
        }
      }
      return -1
    }

    this.read_excel = async function read_excel(file) {
      const worker = new Worker("webWorker.js");
      worker.onerror = (err) => console.log(err)
      worker.postMessage({ file })
      worker.onmessage = (e) => {
        const { keyword_list } = e.data
        if (keyword_list.length > 0) {
          this.setState({ 
            keywords: keyword_list, 
            stimaGPT: (keyword_list.length * 0.0182).toFixed(2)
          }, () => {
            this.handleShow();
          })
        } else {
          this.upload()
        }
        worker.terminate()
      }
    }

    this.job_status_request = (job_id) => {
      let formData = new FormData()
      formData.append('job_id', job_id)
      api
        .post('job_results/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'application/json'
        })
        .then(response => {
          if (response.status === 200) this.setState({ job_results: response.data.data })
        })
        .catch(err => {
          console.log(err.message)
          this.setState({
            error: err,
            loading: false
          })
        })
    }

    this.get_check_results = (job_id) => {
      this.job_status_request(job_id)
      setTimeout(() => {
        if (!this.state.error) {
          if (this.state.job_results === false) {
            this.job_status_request(job_id)
            this.get_check_results(job_id);
          } else {
            if (this.state.job_results === 0) {
              this.upload()
            } else {
              this.setState({
                missing: {
                  is_missing: true,
                  is_all: this.state.job_results === this.state.keywords.length ? true : false,
                  count: this.state.job_results === this.state.keywords.length ? 0 : this.state.job_results
                },
                job_results: false,
                loading: false
              })
            }
          }
        }
      }, 10000)
    }

    this.upload = () => {
      this.setState({ loading: true, error: false, keywords: [] })
      let formData = new FormData()

      formData.append('nome', this.state.nome)
      formData.append('keywords', JSON.stringify(this.state.keywords))
      formData.append('location', this.state.location)
      formData.append('mail', this.state.mail)
      formData.append('customer', this.state.customer)
      formData.append('brand', this.state.brand)
      formData.append('sector', this.state.sector)
      formData.append('file', this.state.file)
      // Al primo caso ci arrivo premendo "Yes" dopo il controllo se qualcosa manca, al secondo con il checkbox
      formData.append('ignore', this.state.missing.is_missing || this.state.ignore)

      api
        .post('insert/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'application/json'
        })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              inserted: true
            })
          }
          else {
            console.log("Wrong status code")
          }
          this.setState({ loading: false })
        })
        .catch(err => {
          console.log(err.message)
          this.setState({
            error: err,
            loading: false
          })
        })
    }

    // funzioni della modale delle stime
    this.handleSave = () => {
      this.setState({ modalShow: false}, () => {
        this.upload();
      });
    }
    this.handleClose = () => this.setState({ loading: false, modalShow: false});
    this.handleShow = () => this.setState({ modalShow: true});

    // funzioni del form
    this.handleSubmit = event => {
      event.preventDefault();
      this.setState({ loading: true, error: false, keywords: [] })
      this.read_excel(this.state.file);
    }

    this.handleBack = event => {
      this.setState({
        keywords: [],
        file: null,
        error: false,
        location: 'it-it',
        inserted: false,
        ignore: false,
        nome: '',
        mail: '',
        job_results: false,
        customer: '',
        brand: '',
        sector: '',
        stimaGPT: 0,
      })
    }

    this.selectedKeywords = keywords => {
      this.setState({
        keywords: keywords
      })
    }

    this.resetMissing = () => {
      this.setState({
        missing: { is_missing: false, is_all: false, count: 0 }
      })
    }
  }

  componentDidMount() {
    this.setState({
      locations: [
        'it-it',
        'en-uk',
        'en-us',
        'de-de',
        'fr-fr',
        'es-es'
      ],
      location: 'it-it'
    })
  }


  render() {
    return (
      <>
        <h1><PlusSquareFill />Insert</h1>
        <Row className='Content-box'>
          {
            this.state.inserted
              ?
              <Done handleBack={this.handleBack} />
              :
              this.state.loading
                ?
                <Loading />
                :
                this.state.missing.is_missing
                  ?
                  <Missing resetMissing={this.resetMissing} missing={this.state.missing} handleBack={this.handleBack} handleUpload={this.upload} keywords={this.state.keywords} />
                  :
                  <Form onSubmit={this.handleSubmit}>
                    {this.state.error ?
                      <>
                        <Row>
                          <ErrorAlert error={this.state.error} />
                        </Row>
                      </>
                      :
                      <></>
                    }
                    <Row>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="mail" style={{ display: 'block' }}>
                            Nome progetto
                          </label>
                          <Form.Control
                            type="text"
                            required
                            placeholder="Nome del progetto"
                            onChange={event => {
                              this.setState({
                                nome: event.target.value
                              })
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="file" style={{ display: 'block' }}>
                            File
                                </label>
                          <input
                            id={'file'}
                            type={'file'}
                            required
                            style={{ border: '0' }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={event => {
                              this.setState({
                                file: event.target.files[0]
                              })
                            }}
                          />
                          <FormText color="muted" align={'left'}>
                            Upload a file with your keywords. <br />
                            <b>The file must contain at least one column named "Keyword"</b>
                          </FormText>
                        </Form.Group>
                      </Col>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="mail" style={{ display: 'block' }}>
                            Mail
                                </label>
                          <Form.Control
                            type="email"
                            required
                            placeholder="Enter email"
                            onChange={event => {
                              this.setState({
                                mail: event.target.value
                              })
                            }}
                          />
                          <FormText color="muted" align={'left'}>
                            <b>
                              Insert an email. You will receive a final report at the specified address
                                    </b>
                          </FormText>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="location" style={{ display: 'block' }}>
                            Location
                                </label>
                          <select
                            id="location"
                            className='form-control'
                            value={this.state.location}
                            required
                            style={{ height: '44px' }}
                            onChange={event => {
                              this.setState({
                                location: event.target.value
                              })
                            }}
                          >
                            {this.state.locations.map(location => {
                              return (
                                <option key={location} value={location}>
                                  {location}
                                </option>
                              )
                            })}
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="customer" style={{ display: 'block' }}>
                            Customer
                          </label>
                          <Form.Control
                            type="text"
                            required
                            maxLength="30"
                            placeholder="Enter customer"
                            onChange={event => {
                              this.setState({
                                customer: event.target.value
                              })
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="brand" style={{ display: 'block' }}>
                            Brand
                          </label>
                          <Form.Control
                            type="text"
                            required
                            maxLength="30"
                            placeholder="Enter brand"
                            onChange={event => {
                              this.setState({
                                brand: event.target.value
                              })
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='col-12 col-md-6'>
                        <Form.Group>
                          <label htmlFor="sector" style={{ display: 'block' }}>
                            Sector
                          </label>
                          <Form.Control
                            type="text"
                            required
                            maxLength="255"
                            placeholder="Enter sector"
                            onChange={event => {
                              this.setState({
                                sector: event.target.value
                              })
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button type="submit">Invia</Button>
                  </Form>
          }
          <Modal show={this.state.modalShow} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Stima GPT</Modal.Title>
            </Modal.Header>
            <Modal.Body>Il costo di questa esecuzione utilizzando GPT-Vision API è stimato: {this.state.stimaGPT}$</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Annulla
              </Button>
              <Button variant="primary" onClick={this.handleSave}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </>
    )
  }
}