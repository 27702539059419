import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { HouseDoorFill } from 'react-bootstrap-icons';

import InsertPage from '../../media/images/insert_page.jpg'
import Results from '../../media/images/results.png'
import ResultsTable from '../../media/images/table.png'

import '../../css/home.css'

export default class Home extends React.Component {
    render() {
        return (
            <>
            <h1><HouseDoorFill/>Home</h1>
            <Row className='Content-box'>
                <Col className='col-12'>
                    <p>
                        Hello! Welcome to our <strong>Keyword Intentions Tool</strong>. Here's a quick view of the main functionalities:
                    </p>
                </Col>
                <Col className='text-col col-12 col-lg-6'>
                    <p>
                        First, you can go to the "<em>New analysis</em>" section. Here you can upload a studiokw that must contains at least one column "Keywords", that represents the list of 
                        keywords you want to analyze. You must specify a country and a language that will be used as parameters to obtain <em>serp</em> in your desired location and country. You'll also
                        need to provide an email address, since the analysis it's quite slow. The results will be sent as an attachment to the specified email as soon as possible.
                    </p>
                    <Image src={InsertPage} alt="Insert page image" fluid/>  
                    <p>
                        If all went smoothly, you will receive the results after some time (approximately 8-10 minutes are required to process 100 keywords). The results file contains 
                        all percentages assigned to the three main intents: <em>Informational, Navigational, Transactional</em>. Plus, some additional information are provided:
                        <ul>
                            <li>- <em>Local</em>: if the keyword express a locality intent or not</li>
                            <li>- <em>Ecommerce</em>: the percentage of organic results whose domain is identified as an ecommerce site</li>
                            <li>- <em>Multibrand</em>: the percentage of ecommerce sites that are multibrand</li>
                        </ul>
                    </p> 
                    <Image src={Results} alt="Excel results example" fluid/>  
                </Col>
                <Col className='text-col col-12 col-lg-6'>
                    <p>
                        Once you've received the results you can decide to analyze them as you please. We'd like to mention that our tool provide another section, "<em>Results</em>", in which 
                        you can upload your file or select a file from a list of previously stored files in our database (every time you upload a studiokw, the results are stored there, updating any results 
                        previously generated from a file with the same name). Once you're done, you'll see your data represented in a nice-looking table, with the same columns as the mail attachment,
                        plus one column <em>Total</em> that shows more clearly the distribution of the intents. You can also decide to export the table data, if you lost your local results by accident.
                        You can try the table out right now by selecting some previous analysis!
                    </p>
                    <Image src={ResultsTable} alt="Results table example" fluid/>  
                </Col>
            </Row>
            </>
        )
    }
  }