import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Image } from 'react-bootstrap';

// Layout
import TopBar from './components/layout/TopBar.js';
import Menu from './components/layout/Menu.js';

// Content
import Home from './components/content/Home.js'
import Insert from './components/content/Insert.js'
import Old from './components/content/Old.js'
import Check from './components/content/Check.js'
import Results from './components/content/Results.js'

// Css
import './css/app.css'

import WebrankingLogo from './media/images/webranking_logo.png'

// Auth
import auth from './auth/service'

// Tag Manager
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TRZJGFB',
}

class App extends React.Component {
  constructor(props) {
    super()

    this.state = {}

    this.handleLogout = this.handleLogout.bind(this)
    this.getProfileId = this.getProfileId.bind(this)
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  handleLogout() {
    auth.logout()
  }

  getProfileId() {
    if (this.state.profile && auth.isAuthenticated) {
      return this.state.profile.name
    }
    return ''
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs)
    }
    if (localStorage.getItem('isLoggedIn') === 'true') {
      auth.renewSession().then(() => {
        if (!auth.userProfile) {
          auth.getProfile().then(() => {
            this.setState({ profile: auth.userProfile })
          })
        } else {
          this.setState({ profile: auth.userProfile })
        }
      })
    }
    // }
  }

  render() {
    return (
      <Container fluid>
        {/* TopBar */}
        <Row className='TopBar'>
          <Col className='WebrankingLogo Logo d-none d-md-flex'>
            <Image src={WebrankingLogo} alt="Webranking Logo" fluid />
          </Col>
          <Col>
            <TopBar handleLogout={this.handleLogout} getProfileId={this.getProfileId} />
          </Col>
        </Row>

        {/* Page content */}
        <Row className='Page'>
          <Router>
            {/* Menu that appears on medium-big screens */}
            <Col className='Menu d-md-flex'>
              <Menu />
            </Col>
            {/* Menu that appers only when toggle is pressed on small screen and disappear on resize or on toggle off */}
            <Col className='MobileMenu visibility-hidden d-flex d-md-none'>
              <Menu />
            </Col>
            <Col className='Content col-md-12 d-md-flex'>
              <Container className='Content-container'>
                <Switch>
                  <Route path="/results">
                    <Results />
                  </Route>
                  <Route path="/insert">
                    <Insert />
                  </Route>
                  <Route path="/old">
                    <Old />
                  </Route>
                  <Route path="/check">
                    <Check />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Container>
            </Col>
          </Router>
        </Row>
      </Container>
    );
  }
}

export default App;
