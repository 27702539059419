import React from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import { List, BoxArrowRight } from 'react-bootstrap-icons';

import '../../css/top_bar.css'

export default class TopBar extends React.Component {
    constructor(props) {
        super()

        this.handleToggle = () => {
            var mobile_menu = document.getElementsByClassName('MobileMenu')[0];
            var content = document.getElementsByClassName('Content')[0];
            
            mobile_menu.classList.toggle('visibility-hidden')
            content.classList.toggle('hidden')
        }

        this.handleLogout = props.handleLogout
        this.getProfileId = props.getProfileId
    }

    render() {
        return (
            <>
                {/* TopBar on Medium-Big screens */}
                <Row className='Logged d-none d-md-flex align-items-center'>
                    <Col className='Info col-11'>
                        <strong>Logged in:</strong><br />
                        <small>{this.getProfileId()}</small>
                    </Col>
                    <Col className='LogoutButton col-1 justify-content-center'>
                            <Button onClick={this.handleLogout}><BoxArrowRight /></Button>
                    </Col>
                </Row>
                {/* TopBar on Small screens */}
                <Row className='Logged d-flex d-md-none'>
                    <Col className='ToggleButton col-6 justify-content-center'>
                        <Button onClick={this.handleToggle}><List className='MenuToggle'/></Button>
                    </Col> 
                    <Col className='LogoutButton col-6 justify-content-center'>
                            <Button onClick={this.handleLogout}><BoxArrowRight /></Button>
                    </Col>
                </Row>
            </>
        )
    }
  }