import React from 'react';
import { Row, Container, Spinner, ProgressBar } from 'react-bootstrap';
import { UiChecks, XSquareFill } from 'react-bootstrap-icons';
import MaterialTable from 'material-table'

import { api } from '../utils/requests'

import '../../css/check.css'


class ShowOverview extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        this.columns = props.columns
    }
    render() {
        return (
            <>
                {
                    (this.data && this.data.length > 0) ?
                        <MaterialTable title=''
                            data={this.data}
                            columns={this.columns}
                            options={
                                {
                                }
                            }
                        />
                        :
                        <Row className='Content-box'>
                            <p className='no-found'>No jobs found..</p>
                        </Row>
                }
            </>
        )
    }
}



class LoadingOverview extends React.Component {
    render() {
        return (
            <Container className='spinner-container'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        )
    }
}


class JobProgress extends React.Component {
    constructor(props) {
        super()

        this.status = props.status
        this.started = props.started
        this.count = props.count

        this.state = {
            progress: '0'
        }

        this.estimateProgress = () => {
            if (this.status === 'started') {
                const start_date = new Date(this.started)
                let now = new Date()
                now.setHours(now.getHours() - 1)
                const diffMs = (now - start_date);
                const diffS = diffMs / 1000
                let estimated_max

                process.env.NODE_ENV === 'production' ? estimated_max = this.count * 4 : estimated_max = this.count * 10

                if (diffS > estimated_max) {
                    this.setState({ progress: '99' })
                    return
                }
                else {
                    let percentage = Math.round(diffS * 100 / estimated_max)
                    this.setState({ progress: percentage.toString() })
                    return
                }
            }
            if (this.status === 'finished') {
                this.setState({ progress: '100' })
                return
            }
            this.setState({ progress: null })
        }
    }

    componentDidMount() {
        this.estimateProgress()
        this.interval = setInterval(() => this.estimateProgress(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        return (
            <>
                {
                    this.state.progress ?
                        <ProgressBar now={this.state.progress} variant='info' label={`${this.state.progress}%`} />
                        : <></>
                }
            </>
        )
    }
}


export default class Check extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: []
        }

        this.columns = [
            {
                title: 'ID',
                field: 'id'
            },
            {
                title: 'Status',
                field: 'status',
                render: rowData => <button type="button" className={this.statusButtonColor(rowData.status)}>{rowData.status.toUpperCase()}</button>
            },
            {
                title: 'Started',
                field: 'started_at',
                render: rowData => <>{rowData.started_at ? rowData.started_at.split("T", 1) : '-'}</>
            },
            {
                title: 'Creator',
                field: 'creator',
                render: rowData => <>{rowData.creator ? rowData.creator.split("@", 1) : '-'}</>
            },
            {
                title: 'File',
                field: 'file',
                render: rowData => <>{rowData.file ? rowData.file.split(".", 1) : '-'}</>
            },
            {
                title: 'Keywords',
                field: 'count',
            },
            {
                title: 'Locale',
                field: 'locale',
            },
            {
                title: 'Progress % (estimate)',
                render: rowData => <JobProgress status={rowData.status} count={rowData.count} started={rowData.started_at} />
            },
            {
                title: 'Stop job',
                render: rowData => rowData.status === "started" ?
                    <button id="fit-button" onClick={(e) => {
                        e.preventDefault()
                        if (window.confirm('Delete this alert?')) {
                            this.stopJob(rowData.id)
                        }
                    }}><XSquareFill id="fit-icon"/></button>
                    : <></>
            }
        ]
    }

    statusButtonColor(status) {
        if (status === 'failed')
            return 'status-btn btn-danger'
        if (status === 'finished')
            return 'status-btn btn-success'
        if (status === 'started')
            return 'status-btn btn-primary'
        if (status === 'queued')
            return 'status-btn btn-secondary'
        return 'status-btn btn-warning'
    }


    estimateProgress(status, started, count) {
        if (status === 'started') {
            const start_date = new Date(started)
            let now = new Date()
            now.setHours(now.getHours() - 1)
            const diffMs = (now - start_date);
            const diffS = diffMs / 1000
            let estimated_max

            process.env.NODE_ENV === 'production' ? estimated_max = count * 2 : estimated_max = count * 3.8

            if (diffS > estimated_max) {
                return '99'
            }
            else {
                let percentage = Math.round(diffS * 100 / estimated_max)
                return percentage.toString()
            }
        }
        if (status === 'finished') {
            return '100'
        }
        return null
    }

    renderProgressEstimate(status, started, count) {
        const progress = this.estimateProgress(status, started, count)
        if (progress) {
            return (
                <>
                    <ProgressBar now={progress} variant='info' label={`${progress}%`} />
                </>
            )
        } else {
            return <>-</>
        }
    }

    getTableDatas() {
        api
            .get('worker_overview/')
            .then(res => {
                this.setState({
                    isLoading: false,
                    data: res.data.data
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isLoading: false,
                })
            })
    }


    stopJob(job_id) {
        let formData = new FormData()
        formData.append('job_id', job_id)
        debugger;
        api
            .post('stop/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'application/json'
            })
            .then(response => {
                if (response.status === 200) {
                    debugger;
                    this.setState({ isLoading: true })
                    this.getTableDatas()
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    componentDidMount() {
        this.getTableDatas()
    }

    render() {
        return (
            <>
                <h1><UiChecks />Check</h1>
                {
                    this.state.isLoading
                        ?
                        <LoadingOverview />
                        :
                        <ShowOverview columns={this.columns} data={this.state.data} />
                }
            </>
        )
    }
}