import React from 'react';
import { Row, Container, Spinner } from 'react-bootstrap';
import { FolderFill } from 'react-bootstrap-icons';
import MaterialTable from 'material-table'

import { api } from '../utils/requests'

import '../../css/old.css'


class ShowOverview extends React.Component {
    constructor(props) {
        super()

        this.data = props.data
        this.columns = props.columns
    }
    render() {
        return (
            <>
                {
                    (this.data && this.data.length > 0) ?
                        <MaterialTable title=''
                            data={this.data}
                            columns={this.columns}
                            options={
                                {
                                    filtering: true,
                                }
                            }
                        />
                        :
                        <Row className='Content-box'>
                            <p className='no-found'>No analysis found..</p>
                        </Row>
                }
            </>
        )
    }
}



class LoadingOverview extends React.Component {
    render() {
        return (
            <Container className='spinner-container'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        )
    }
}


class Actions extends React.Component {
    constructor(props) {
        super()
        this.filename = props.filename

        this.state = {
            loading: false,
            error: false
        }

        this.handleDownload = () => {
            this.setState({ loading: true })
            let formData = new FormData()
            formData.append('filename', this.filename)

            api.post('download/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.filename}.xlsx`;
                    link.click();
                    this.setState({ loading: false })
                })
                .catch(err => {
                    console.log(err.message)
                    this.setState({ loading: false, error: true })
                })
        }

        this.handleUpload = () => {
            this.setState({ loading: true })
            let formData = new FormData()
            formData.append('filename', this.filename)

            api.post('upload-bq/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    if (response.status === 200) this.setState({ loading: false })
                    else this.setState({ loading: false, error: true })
                })
                .catch(err => {
                    console.log(err.message)
                    this.setState({ loading: false, error: true })
                })
        }
    }


    render() {
        return (
            <>
                {
                    this.state.error ?
                        <p style={{ color: 'red' }}><b>Non è stato possibile completare la richiesta</b></p>
                        :
                        this.state.loading ?
                            <Container className='spinner-container'>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </Container>
                            :
                            <>
                                <button onClick={this.handleDownload} type="button" className="download-btn btn-success">Download</button>
                                <button onClick={this.handleUpload} type="button" className="upload-btn btn-info">Upload BQ</button>
                            </>
                }
            </>
        )
    }
}


export default class Old extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: []
        }

        this.columns = [
            {
                title: 'Filename',
                field: 'filename',
                render: rowData => rowData.filename ? <p>{rowData.filename}</p> : <p>-</p>
            },
            {
                title: 'Uploader',
                field: 'uploader',
                render: rowData => rowData.uploader ? <p>{rowData.uploader}</p> : <p>-</p>
            },
            {
                title: 'Locale',
                field: 'locale',
                render: rowData => rowData.locale ? <p>{rowData.locale}</p> : <p>-</p>
            },
            {
                title: 'Customer',
                field: 'customer',
                render: rowData => rowData.customer ? <p>{rowData.customer}</p> : <p>-</p>
            },
            {
                title: 'Sector',
                field: 'sector',
                render: rowData => rowData.sector ? <p>{rowData.sector}</p> : <p>-</p>
            },
            {
                title: 'Brand',
                field: 'brand',
                render: rowData => rowData.brand ? <p>{rowData.brand}</p> : <p>-</p>
            },
            {
                title: 'Download/Upload',
                render: rowData => <Actions filename={rowData.filename} />
            },
        ]
    }


    componentDidMount() {
        api
            .get('detailed_uploaded/')
            .then(res => {
                this.setState({
                    isLoading: false,
                    data: res.data.list
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isLoading: false,
                })
            })
    }

    render() {
        return (
            <>
                <h1><FolderFill />Old</h1>
                {
                    this.state.isLoading
                        ?
                        <LoadingOverview />
                        :
                        <ShowOverview columns={this.columns} data={this.state.data} />
                }
            </>
        )
    }
}